import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';

import { UserContext, BaseUrlContext } from '@core/context';

import DiscussExcerpt from './Excerpt';
import DiscussFull from './Full';

const DiscussPost = ({ post, excerpt }) => {
  const { hub2voters, ...p } = post;
  const { _id: userId } = useContext(UserContext);
  let baseUrl = useContext(BaseUrlContext);
  baseUrl = baseUrl === '/' ? '' : baseUrl;
  const [userVote, setUserVote] = useState({
    voteCount: p?.vote_count,
    hasVoted: hub2voters.indexOf(userId) !== -1,
    voters: hub2voters,
  });

  return excerpt ? (
    <DiscussExcerpt {...{ baseUrl, post, userVote, setUserVote }} />
  ) : (
    <DiscussFull {...{ baseUrl, post, userVote, setUserVote }} />
  );
};

DiscussPost.propTypes = {
  excerpt: PropTypes.bool,
  post: PropTypes.object,
};

DiscussPost.defaultProps = {
  excerpt: false,
};

export default DiscussPost;
