import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '@core/context';
import useEnvInfo from '@core/hooks/useEnvInfo';

import Button from '@ui/Button';
import DateLine from '@ui/DateLine';
import Flex from '@ui/Flex';
import RDMD from '@ui/RDMD';

import { handlePostVote } from '../helpers';
import { rdmdOpts } from '../options';
import classes from '../style.module.scss';

const Metabox = props => {
  const { answered, comments, userVote, setUserVote, userId, discussUrl, permissions } = props;
  return (
    <Flex align="stretch" gap="xs" justify="start" layout="col">
      <Flex gap="xs">
        <Button
          className={classes['DiscussPost-button']}
          kind={userVote.hasVoted ? 'primary' : 'secondary'}
          onClick={e => {
            // Don't navigate to the post if we've clicked on the vote button! Instead, submit a vote.
            e.preventDefault();
            return handlePostVote({ permissions, userVote, setUserVote, userId, discussUrl });
          }}
          outline
          size="md"
        >
          <i className={`${classes['DiscussPost-button_icon']} icon-thumbs-up-2`} /> {userVote.voteCount || 0}
        </Button>

        <Button className={classes['DiscussPost-button']} kind="secondary" outline size="md">
          <i className={`${classes['DiscussPost-button_icon']} icon-discussions`} /> {comments?.length || 0}
        </Button>
      </Flex>
      {!!answered && (
        <div className={classes['DiscussPost-metabox_answered']}>
          <span className={`${classes.DiscussPost_icon} icon-check-circle`} />
          ANSWERED
        </div>
      )}
    </Flex>
  );
};

const Tags = props => (
  <>
    {props.tags?.map((tag, i) => {
      return (
        <span key={`tag-${tag}-${i}`} className={classes.DiscussPostActions_wrapper}>
          <Button
            className={classes.DiscussPostActions_button}
            kind="secondary"
            outline
            size="sm"
            to={`/discuss?tag=${tag}`}
          >
            {tag}
          </Button>
        </span>
      );
    })}
  </>
);

const PostContent = props => (
  <>
    <RDMD className={classes['DiscussPost-abbrbody']} excerpt opts={rdmdOpts}>
      {props.body}
    </RDMD>
    <div className={classes.DiscussPostActions}>
      <Tags {...{ tags: props.tags }} />
    </div>
  </>
);

const PostDetails = props => {
  const {
    postUser: { name },
    createdAt,
  } = props;
  return <DateLine className={classes['DiscussPost-date']} prefix={`Posted by ${name}`} time={createdAt} />;
};

const DiscussExcerpt = ({ baseUrl, post, userVote, setUserVote }) => {
  const { effective_user: postUser, body, title, id, tags, solved: answered, comments, createdAt } = post;
  const { isClient } = useEnvInfo;
  const { permissions, _id: userId } = useContext(UserContext);

  const discussUrl = `${isClient ? window.location.pathname : `${baseUrl}/discuss/${post.id}`}`;

  return (
    <Link className={`${classes.DiscussPost} ${classes['DiscussPost-abbreviated']}`} to={`/discuss/${id}`}>
      <Metabox {...{ answered, comments, userVote, setUserVote, userId, discussUrl, permissions }} />
      <div className={classes['DiscussPost-content']}>
        <h2 className={classes['DiscussPost-title']}>{title}</h2>
        <PostContent {...{ body, tags }} />
        {!!createdAt && <PostDetails {...{ postUser, createdAt }} />}
      </div>
    </Link>
  );
};

DiscussExcerpt.propTypes = {
  baseUrl: PropTypes.string,
  post: PropTypes.object,
  setUserVote: PropTypes.func,
  userVote: PropTypes.shape({
    hasVoted: PropTypes.bool,
    voteCount: PropTypes.number,
    voters: PropTypes.array,
  }),
};

Metabox.propTypes = {
  answered: PropTypes.bool,
  comments: PropTypes.array,
  discussUrl: PropTypes.string,
  permissions: PropTypes.array,
  setUserVote: PropTypes.func,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  userVote: PropTypes.shape({
    hasVoted: PropTypes.bool,
    voteCount: PropTypes.number,
  }),
};

PostContent.propTypes = {
  body: PropTypes.string,
  tags: PropTypes.array,
};

PostDetails.propTypes = {
  createdAt: PropTypes.string,
  postUser: PropTypes.shape({
    name: PropTypes.string,
  }),
};

Tags.propTypes = {
  tags: PropTypes.array,
};

export default DiscussExcerpt;
